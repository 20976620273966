<template>
  <div class="layout-main detail-sub-page journey-detail-member">
    <div class="journey">
      <div class="page-nav">
        <router-link class="back-btn" :to="{name: 'JourneyDetail'}">
          <Icon name="arrow_back_ios" size="24" />
        </router-link>
        <h2 class="fs-h2">參與人數</h2>
        <h5 class="fs-h5">
          <span>（ </span>
          <span v-text="joinedMembers.length + 1"></span>
          <span> / </span>
          <span></span>
          <span> ）</span>
        </h5>
      </div>
      <div class="page-content">
        <div class="team-members-number with-hr d-flex align-items-center">
          <div class="avatar-group">
            <div class="avatar circle-trail">
              <img :src="joinedOwner.pic_url" alt="avatar">
            </div>
          </div>
          <div class="fs-body-14">
            <span>主辦人</span>
          </div>
        </div>

        <div class="team-members-number with-hr d-flex" v-for="(item, index) in joinedMembers" :key="index">
          <div class="avatar-group">
            <div class="avatar circle-trail">
              <img :src="item.user.pic_url" alt="avatar">
            </div>
            <div class="edit-link" v-if="item.user.serial === user.serial && !isExpired">
              <a :href="'/journey/' + journey._id + '/join'">編輯</a>
            </div>
          </div>
          <div class="no-permission fs-body-14 align-items-center d-flex" v-if="isExpired || !item.realName">
            <span>無瀏覽資料權限</span>
          </div>
          <!--								<div class="no-permission fs-body-14 align-items-center d-flex" v-if="!isExpired && !item.realName">-->
          <!--									<span>無瀏覽資料權限</span>-->
          <!--								</div>-->
          <div class="detail-list fs-body-14 align-items-center" v-if="!isExpired">
            <div class="detail-item cursor-pointer" v-if="item.realName" @click="getTextCopy($event)">
              <div class="item-title">姓名</div>
              <div class="item-desc" v-text="item.realName"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.phone" @click="getTextCopy($event)">
              <div class="item-title">電話</div>
              <div class="item-desc" v-text="item.phone"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.address" @click="getTextCopy($event)">
              <div class="w-100 d-flex flex-nowrap">
                <div class="item-title">地址</div>
                <div class="item-desc" v-text="(item.fullAddress && item.fullAddress.length>0)? item.fullAddress : item.address"></div>
              </div>
              <span class="success-alert"></span>
            </div>

            <div class="detail-item cursor-pointer" v-if="item.email" @click="getTextCopy($event)">
              <div class="item-title">Email</div>
              <div class="item-desc" v-text="item.email"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.personalId" @click="getTextCopy($event)">
              <div class="item-title">身分證字號</div>
              <div class="item-desc" v-text="item.personalId"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.birthday" @click="getTextCopy($event)">
              <div class="item-title">出生年月日</div>
              <div class="item-desc" v-text="item.birthday"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.emergencyName" @click="getTextCopy($event)">
              <div class="item-title">緊急聯絡人</div>
              <div class="item-desc" v-text="item.emergencyName"></div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" v-if="item.emergencyPhone" @click="getTextCopy($event)">
              <div class="item-title">緊急聯絡人電話</div>
              <div class="item-desc" v-text="item.emergencyPhone"></div>
              <span class="success-alert"></span>
            </div>

            <div class="detail-item cursor-pointer" style="display: none;">
              <div class="w-100 d-flex flex-nowrap">
                <div class="item-title">地址區域碼</div>
                <div class="item-desc" v-text="(item.cityCode && item.cityCode.length>0)? item.cityCode : ''"></div>
              </div>
              <span class="success-alert"></span>
            </div>
            <div class="detail-item cursor-pointer" style="display: none;">
              <div class="w-100 d-flex flex-nowrap">
                <div class="item-title">剩餘地址</div>
                <div class="item-desc" v-text="item.address"></div>
              </div>
              <span class="success-alert"></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import setHideNav from "@/assets/js/setHideNav";

export default {
  mixins: [setHideNav],
  name: 'JourneyDetailMember',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      pageId: null,
      journey: null,
      joinedOwner: {},
      joinedMembers: [],
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    isExpired: function() {
      const theDayAfterEndDate = moment(this.journey.endDate);
      theDayAfterEndDate.add(1, 'day');
      return moment().isAfter(theDayAfterEndDate);
    },
	},
  watch: {
    
  },
  async mounted() {
    this.pageId = this.$route.params.id;
    await this.refresh(this.pageId);
	},
  methods: {
    async refresh(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.journey = await this.$store.dispatch('api/getJourneyDetailPromise', journeyId);
        const joinedData = await this.$store.dispatch('api/getJourneyJoinSubmitListPromise', journeyId)
        console.log(joinedData)
        if (joinedData !== null) {
          this.joinedOwner = Object.assign({}, this.joinedOwner, joinedData.owner);
          joinedData.list.map((item) => {
            if (item.user.serial === this.user.serial) {
              this.joinedMembers.unshift(item);
            } else {
              this.joinedMembers.push(item);
            }
          })
        }
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    copyMsg(msg) {
      let node = document.createElement("span");
      node.innerText = msg;
      node.setAttribute('style', 'position:absolute;left:0;top:0;opacity:0;');
      document.body.appendChild(node);
      let selection = window.getSelection();
      selection.removeAllRanges();
      let range = document.createRange();
      range.selectNode(node);
      selection.addRange(range);

      // try {
      //   var successful = document.execCommand('copy');
      //   var sysmsg = successful ? 'successful' : 'unsuccessful';
      //   console.log(sysmsg);
      // } catch (err) {
      //   console.error(err);
      // }

      selection.removeAllRanges();
      node.remove();
    },
    getTextCopy: function(event) {
      const target = event.currentTarget;
      let linkCopy = target.querySelector('.item-desc').innerText;
      this.copyMsg(linkCopy);
      const animated = target.querySelector('.success-alert');
      animated.classList.add('active');
      animated.onanimationend = () => {
        animated.classList.remove('active');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@import "src/assets/scss/detailSubPage";
.journey-detail-member {
  .page-nav {
    .fs-h2 {
      margin-bottom: 1rem;
    }
    .fs-h5 {
      color: $color-bk-tp-25;
      margin-bottom: 0;
    }
  }
  .page-content {
    padding-top: 0;
  }
  .team-members-number {
    color: $color-bk-tp-85;
    padding: 36px 0;
    &.with-hr:not(:last-child) {
      border-bottom: 1px solid $color-black-200;
    }
    .no-permission {
      color: $color-bk-tp-25;
    }
    .avatar-group {
      flex: 0 0 auto;
      margin-right: 36px;
      .edit-link {
        text-align: center;
        margin-top: 8px;
      }
    }
  }
}
</style>
